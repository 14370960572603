import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import Call from '../../components/Call';

const Contact = props => (
  <Layout bodyClass="page-contact">
    <SEO title="Contact" />
    <div className="intro intro-small">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Contacto</h1>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <Call button="onlyWhats" />
        </div>
        <div className="col-8">
          <h4 className="mt-4">Horario de Oficina</h4>
          <table className="table table-sm opening-hours-table">
            <tbody>
              <tr>
                <td className="day font-weight-bold">Lunes</td>
                <td className="opens">10:00am</td>
                <td>-</td>
                <td className="closes">7:00pm</td>
              </tr>
              <tr>
                <td className="day font-weight-bold">Martes</td>
                <td className="opens">10:00am</td>
                <td>-</td>
                <td className="closes">7:00pm</td>
              </tr>
              <tr>
                <td className="day font-weight-bold">Miércoles</td>
                <td className="opens">10:00am</td>
                <td>-</td>
                <td className="closes">7:00pm</td>
              </tr>
              <tr>
                <td className="day font-weight-bold">Jueves</td>
                <td className="opens">10:00am</td>
                <td>-</td>
                <td className="closes">7:00pm</td>
              </tr>
              <tr>
                <td className="day font-weight-bold">Viernes</td>
                <td className="opens">10:00am</td>
                <td>-</td>
                <td className="closes">7:00pm</td>
              </tr>
              <tr>
                <td className="day font-weight-bold">Sábado</td>
                <td className="opens">2:00pm</td>
                <td>-</td>
                <td className="closes">6:00pm</td>
              </tr>
              <tr>
                <td className="day font-weight-bold">Domingo</td>
                <td className="opens">Cerrado</td>
                <td />
                <td className="closes" />
              </tr>
            </tbody>
          </table>
          <h2>CDMX</h2>
          <iframe
            title="CL Abogados Laborales"
            frameBorder="0"
            style={{ width: '98%', height: '400px', border: '0' }}
            src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ3fyDwnT_0YURNbinErQces0&key=AIzaSyCFjEGZYmeX0OtTqAwuYtK4TRpqMmqvtVg"
          />
          <br/>
          <br/>
          <h2>Estado de México</h2>
          <iframe
            title="CL Abogados Laborales (Despacho 12-09, Plaza Esmeralda)"
            frameBorder="0"
            style={{ width: '98%', height: '400px', border: '0' }}
            src="https://www.google.com/maps/embed/v1/place?q=Tlatlaya+9,+Centro+Urbano,+54700+Cuautitl%C3%A1n+Izcalli&key=AIzaSyCFjEGZYmeX0OtTqAwuYtK4TRpqMmqvtVg"
          />
          <p>Despacho 12-09, Plaza Esmeralda</p>
        </div>
      </div>
    </div>
  </Layout>
);

export default Contact;
