import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import { BrowserView, MobileView } from 'react-device-detect';
import whatsappLogo from '../images/whatsapp.png';

const displayStyles = {
  display: 'inline',
};
class Call extends React.Component {
  render() {
    return (
      <div className="call">
        <div className="call-box-top">
          <div className="call-phone">
            <i className="material-icons icon-contact">smartphone</i>
            <BrowserView style={displayStyles}>
              {this.props.data.site.siteMetadata.contact.phone}
            </BrowserView>
            <MobileView style={displayStyles}>
              <a
                href={`tel:${
                  this.props.data.site.siteMetadata.contact.phone
                }`}
              >
                {this.props.data.site.siteMetadata.contact.phone}
              </a>
            </MobileView>
          </div>
          <div className="call-email">
          <i className="material-icons icon-contact">email</i>
            <a
              className="mail"
              href={`mailto:${this.props.data.site.siteMetadata.contact.email}`}
            >
              {this.props.data.site.siteMetadata.contact.email}
            </a>
          </div>
        </div>
        {this.props.button && !(this.props.button === 'onlyWhats') ? (
          <div className="call-box-bottom">
            <Link to="/contact" className="button btn-left">
              Contacto
            </Link>
            <a
              href="https://api.whatsapp.com/send?phone=5215612977788&text=Solicito%20asesor%C3%ADa%20laboral&source=website&data=onCLick"
              target="_blank"
              rel="noopener noreferrer"
              className="button btn-right"
            >
              <img
                className="whatsapp-logo"
                alt="whatsapp"
                src={whatsappLogo}
              />
            </a>
          </div>
        ) : (
          <div className="call-box-bottom">
            <a
              href="https://calendly.com/conciliacionlaboral"
              target="_blank"
              rel="noopener noreferrer"
              className="button btn-left btn-appoiments"
            >
              <i className="material-icons calendly">calendar_today</i>
              Citas
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=5215612977788&text=Solicito%20asesor%C3%ADa%20laboral&source=website&data=onCLick"
              target="_blank"
              rel="noopener noreferrer"
              className="button btn-right"
            >
              <img
                className="whatsapp-logo"
                alt="whatsapp"
                src={whatsappLogo}
              />
            </a>
          </div>
        )}
      </div>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            description
            contact {
              email
              phone
            }
          }
        }
      }
    `}
    render={data => <Call button={props.button} data={data} />}
  />
);
